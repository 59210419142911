<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de traslado</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component id="divPrint" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <!--Motive-->
        <div v-if="document.motive">
          <vs-textarea disabled="true" label="Motivo" :value="document.motive"></vs-textarea>
        </div>
        <!--Warehouses-->
        <div class="vx-row">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input class="w-full" label="Almacén de salida" disabled="true"
                      :value="document.warehouseOut.name"></vs-input>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input class="w-full" label="Almacén de llegada" disabled="true"
                      :value="document.warehouseIn.name"></vs-input>
          </div>
        </div>
        <!--user-->
        <div class="mt-5">
          <vs-input class="w-full" label="Usuario que registró traslado" disabled="true"
                    :value="document.user.displayName"></vs-input>
        </div>
        
        <!--Products-->
        <div class="mt-8">
          <h6>Productos</h6>
          <vs-table class="mt-5" :data="document.products">

            <template slot="thead">
              <vs-th>Nro. de Parte</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Cantidad</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.numberPart">
                  <p>{{ tr.numberPart }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <template>
                    <vs-input disabled="true" min="1" :max="tr.stock" style="width: 80px" type="number"
                              v-model.number="tr.quantity"/>
                  </template>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {
        products: [],
        warehouseIn: {},
        warehouseOut: {},
        user: {}
      },
      progress: false,
      openSerialDialog: false,
      serials: '',
      selectedProduct: {}
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      this.document = _.cloneDeep(this.data)
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
